import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  MenuItem,
  Stack,
  Typography,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@mui/material';

import { fData } from '../../../../shared/utils/formatNumber';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { UserManager } from '../../../../shared/@types/user';
import { CustomFile } from '../../../../shared/components/upload';
import {
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../shared/components/hook-form';
import { API, API_ENDPOINTS } from '../../../../shared/utils/api';
import Label from '../../../../shared/components/Label';

interface FormValuesProps extends Omit<UserManager, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
}

type Props = {
  isEdit: boolean;
  currentUser?: UserManager;
};

const roles = [
  { value: 'admin', label: 'Admin' },
  { value: 'realtor', label: 'Realtor' },
  { value: 'user', label: 'User' },
];

export default function UserEditForm({ isEdit, currentUser }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [personRole, setPersonRole] = useState<string[]>([]);

  useEffect(() => {
    let userRolesTypes: any[] = [];
    if (currentUser?.user_role) {
      userRolesTypes = currentUser?.user_role.map((el) => {
        return el.type;
      });
    }
    setPersonRole([...userRolesTypes]);
  }, [currentUser?.user_role]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonRole(value);
  };

  const NewUserSchema = Yup.object().shape({
    username: Yup.string().required('Name is required'),
    user_surname: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email(),
  });

  const defaultValues = useMemo(
    () => ({
      username: currentUser?.username || '',
      user_surname: currentUser?.user_surname || '',
      email: currentUser?.email || '',
      avatarUrl: currentUser?.avatarUrl || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const [dense, setDense] = useState(props?.defaultDense || false);

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async (data: FormValuesProps) => {
    if(data.avatarUrl === '') {
      enqueueSnackbar('Please upload avatar!', {variant: 'error'})
    } else {
      if (personRole.length !== 0) {
        try {
          const formData = new FormData();

          Object.keys(data).forEach((formProp) => {
            // @ts-ignore
            if (formProp === 'avatarUrl' && data[formProp]?.name) {
              // @ts-ignore
              formData.append('file', data[formProp]);
            } 
          });

          if (formData.has('file')) {
            // @ts-ignore
            await API.put<any>(API_ENDPOINTS.UPDATE_AVATAR.replace(':id', currentUser.id), formData);
          }

          // @ts-ignore
          await API.put<any>(API_ENDPOINTS.UPDATE_USER.replace(':id', currentUser?.id), data)

          await API.put<any>(API_ENDPOINTS.UPDATE_USER_ROLES, {
            userId: currentUser?.id,
            newRoleTypes: Array.isArray(personRole) ? personRole[0] : personRole,
          });

          reset();
          enqueueSnackbar('Update success!');
          navigate(PATH_DASHBOARD.user.list);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            User is
            {currentUser?.user_role?.map((role) => (
              <Label
                variant={'filled'}
                color={(role.type === 'user' && 'error') || 'success'}
                sx={{ textTransform: 'capitalize', marginLeft: '10px' }}
              >
                {role.description}
              </Label>
            ))}
            <br />
            <br />
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="username" label="Name" />
              <RHFTextField name="user_surname" label="Surname" />
              <RHFTextField name="email" label="Email Address" />
              <FormControl>
                <InputLabel>Role</InputLabel>
                <Select
                  fullWidth
                  name="role"
                  label="Role"
                  value={personRole}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => {
                    if (Array.isArray(selected)) {
                      return (selected as string[]).join(', ');
                    }

                    return selected;
                  }}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={personRole.indexOf(option?.value) > -1} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={personRole.length === 0}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
