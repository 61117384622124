import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { List, ListItem } from '@mui/material';

export default function PrivatePolicy() {
  return (
    <Box component="section">
      <Typography variant="h4" sx={{ pb: 2 }}>
        Privacy Policy
      </Typography>
      <Box component="article" sx={{ display: 'grid', gap: 2 }}>
        <Typography variant="body1">Introduction</Typography>
        <Typography variant="body1">
          Delek Homes, we is committed to protecting the privacy and security of the personal
          information we collect from you. This Privacy Policy describes how we collect, use, and
          disclose your personal information in connection with your use of our website.
        </Typography>
        <Typography variant="body1">Information We Collect</Typography>
        <Typography variant="body1">
          We collect various types of personal information when you visit our website or interact
          with us, including:
          <List>
            <ListItem>
              Personal information you provide directly to us, such as your name, email address, and
              other contact information.
            </ListItem>
            <ListItem>
              Usage information, such as your IP address, browser type, operating system, and other
              technical information about your use of our website.
            </ListItem>
            <ListItem>
              Cookies and other tracking technologies that we use to collect information about your
              use of our website.
            </ListItem>
          </List>
        </Typography>
        <Typography variant="body1">How We Use Your Information</Typography>
        <Typography variant="body1">
          We use your personal information for the following purposes:
          <List>
            <ListItem>To provide and improve our website and services.</ListItem>
            <ListItem>To respond to your inquiries and requests.</ListItem>
            <ListItem>
              To prevent and detect fraud and other unauthorized or illegal activities..
            </ListItem>
            <ListItem>To administer our website.</ListItem>
            <ListItem>To comply with legal obligations.</ListItem>
          </List>
        </Typography>
        <Typography variant="body1">Disclosure of Your Information</Typography>
        <Typography variant="body1">
          We may share your personal information with third parties in the following circumstances:
          <List>
            <ListItem>
              With service providers who perform services on our behalf, such as web hosting,
              analytics, and marketing services.
            </ListItem>
            <ListItem>With our affiliates and business partners.</ListItem>
            <ListItem>
              In connection with a merger, acquisition, or other business transaction.
            </ListItem>
            <ListItem>To comply with legal and regulatory requirements.</ListItem>
          </List>
        </Typography>
        <Typography variant="body1">Changes to this Privacy Policy</Typography>
        <Typography variant="body1">
          We may update this Privacy Policy from time to time. If we make material changes, we will
          provide notice on our website.
        </Typography>
      </Box>
    </Box>
  );
}
