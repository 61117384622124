// hooks
import { useMemo } from 'react';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();
  
  const currentDomain = process.env.REACT_APP_DOMAIN;

  const scr = useMemo(() => {
    if (!currentDomain) {
      return '/' + user?.avatarUrl || '';
    }


    return currentDomain + '/' + user?.avatarUrl || '';
  }, [user?.avatarUrl])

  return (
    <Avatar
      src={scr}
      alt={user?.displayName}
      color={user?.avatarUrl ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
