import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Avatar, Typography, CardContent, Grid, Button, Divider } from '@mui/material';
import { PATH_PAGE } from '../../../../routes/paths';
import { fDate } from '../../../../shared/utils/formatTime';
import { EstateObject } from '../../../../shared/@types/blog';
import Image from '../../../../shared/components/Image';
import Iconify from '../../../../shared/components/Iconify';
import { priceFormat } from '../../../../shared/utils/priceFormat';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FavoriteEvent } from './types';

type Props = {
  post: EstateObject;
  index?: number;
  favoriteId: number | undefined;
  onToggleItemToFavorite: (params: FavoriteEvent) => void;
};

const PriceLabel = styled(Box)(({ theme }) => ({
  left: 14,
  zIndex: 9,
  top: 14,
  position: 'absolute',
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.grey['700'],
  borderRadius: '5px',
  padding: '0 4px',
}));

const LikeBtn = styled(Box)(({ theme }) => ({
  right: 14,
  zIndex: 9,
  top: 14,
  position: 'absolute',
  height: '20px',
  width: '20px',
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.grey['700'],
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '5px',
}));

export default function RealEstateCardSimple({ post, favoriteId, onToggleItemToFavorite }: Props) {
  const { realtor, images, price, id } = post;

  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
        <PriceLabel>{priceFormat(price)}</PriceLabel>
        <LikeBtn
          onClick={() => {
            onToggleItemToFavorite({ estateId: id, favoriteId });
          }}
        >
          {favoriteId ? (
            <Iconify icon={'ant-design:heart-filled'} color="red" />
          ) : (
            <Iconify icon={'akar-icons:heart'} />
          )}
        </LikeBtn>
        <Box
          sx={{
            left: 14,
            zIndex: 9,
            bottom: 14,
            height: 40,
            position: 'absolute',
            borderRadius: '18%/50%',
            backdropFilter: 'blur(15px)',
            display: 'flex',
          }}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              marginTop: '4px',
              marginLeft: '4px',
            }}
            alt={realtor?.username}
            src={realtor?.avatarUrl}
          />
          <Box
            sx={{
              display: 'flex',
              color: '#fff',
              fontSize: '12px',
              marginLeft: 1,
              paddingRight: 2,
            }}
            alignItems="center"
          >
            {realtor?.username}
            <br />
            {realtor?.user_surname}
          </Box>
        </Box>
        <Image alt="cover" src={images[0]} ratio="4/3" />
      </Box>

      <PostContent post={post} />
    </Card>
  );
}

// ----------------------------------------------------------------------

type PostContentProps = {
  post: EstateObject;
};

export function PostContent({ post }: PostContentProps) {
  const { id, title, createdAt, sqft, address, bedrooms, garage, bathrooms, city, zipCode, state } = post;
  const linkTo = PATH_PAGE.realEstateIdView(id);

  return (
    <CardContent
      sx={{
        width: 1,
      }}
    >
      <Typography
        gutterBottom
        variant="caption"
        component="div"
        sx={{
          color: 'text.disabled',
        }}
      >
        {fDate(createdAt)}
      </Typography>

      <Divider sx={{ marginBottom: 2 }} />

      <Grid container spacing={1} marginBottom={2}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h5" sx={{ color: 'text.primary' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography sx={{ color: 'text.secondary' }}>
            <Iconify icon={'carbon:location-filled'} marginRight={1} /> {address}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ marginBottom: 2 }} />

      <Grid container spacing={1} marginBottom={4}>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'fa:th-large'} marginRight={1} /> Sqft: {sqft}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'fa:car'} marginRight={1} /> Garage: {garage}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'fa:bed'} marginRight={1} /> Bedrooms: {bedrooms}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'fa:bath'} marginRight={1} /> Bathrooms: {bathrooms}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'healthicons:city'} marginRight={1} /> City: {city}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'gis:layer-poi'} marginRight={1} /> State: {state}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'icon-park-outline:zip'} marginRight={1} /> Zip/Code: {zipCode}
        </Grid>
      </Grid>

      <Box textAlign="center">
        <Button to={linkTo} variant="contained" color="primary" component={RouterLink}>
          More Info
        </Button>
      </Box>
    </CardContent>
  );
}
