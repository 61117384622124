import { EstateObjectsResponse } from '../../../../shared/@types/blog';
import useSettings from '../../../../shared/hooks/useSettings';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useFetch from '../../../../shared/hooks/useFetch';
import { HOST_API } from '../../../../config';
import { API, API_ENDPOINTS } from '../../../../shared/utils/api';
import { formatDistance, subDays } from 'date-fns';
import useTable, { emptyRows } from '../../../../shared/hooks/useTable';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import Page from '../../../../shared/components/Page';
import {
  Box,
  Button,
  Container,
  DialogActions,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Input,
} from '@mui/material';
import HeaderBreadcrumbs from '../../../../shared/components/HeaderBreadcrumbs';
import Scrollbar from '../../../../shared/components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '../../../../shared/components/table';
import { DialogAnimate } from '../../../../shared/components/animate';
import RealEstateInquiriesTableRow from './inquiries/RealEstateInquiriesTableRow';
import { useSortableData } from 'src/shared/hooks/useSortableData';

const TABLE_HEAD = [
  { id: ' ', label: ' ', align: 'left', sortable: true },
  { id: 'property', label: 'Property', align: 'left', sortable: true },
  { id: 'name', label: 'Name', align: 'left', sortable: true },
  { id: 'email', label: 'Email', align: 'left', sortable: true },
  { id: 'phone', label: 'Phone', align: 'left', sortable: true },
  { id: 'message', label: 'Message', align: 'left', sortable: true },
  { id: 'buttons', label: '', align: 'left' },
];

interface TableData {
  id: number | string;
  property: string;
  name: string;
  email: string;
  phone: number | string;
  message: string;
}

export default function RealEstateInquiries() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const deleteId = useRef('');
  const [searchInput, setSearchInput] = useState('');

  const { data, refreshFetch } = useFetch<EstateObjectsResponse>(
    HOST_API + API_ENDPOINTS.GET_INQUIRIES
  );

  const [currentData, setCurrentData] = useState<TableData[]>([]);

  useEffect(() => {
    API.get(API_ENDPOINTS.GET_INQUIRIES).then((data: any) => {
      if (data) {
        setCurrentData(data?.data?.inquiries);
      }
    });
  }, [data]);

  const tableData = useMemo(() => {
    if (!data?.list) return [];

    return data.list.map((i) => {
      return {
        ...i,
        createdAt: formatDistance(subDays(new Date(i.createdAt), 0), new Date(), {
          addSuffix: true,
        }),
      };
    });
  }, [data]);

  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  const newFilteredData = currentData?.filter((el: any) => {
    return (
      el.property.toLowerCase().match(searchInput) ||
      el.name.toLowerCase().match(searchInput) ||
      el.email.toLowerCase().match(searchInput) ||
      el.message.toLowerCase().match(searchInput) ||
      el.phone.match(searchInput)
    );
  });

  const sortableData = useSortableData(newFilteredData, orderBy, order);

  const onConfirmDelete = () => {
    setIsOpenModalDelete(false);
    API.delete<number>(API_ENDPOINTS.DELETE_INQUIRIES.replace(':id', deleteId.current)).then(() => {
      deleteId.current = '';
      refreshFetch();
    });
  };

  const handleDeleteRow = (id: string) => {
    deleteId.current = id;
    setIsOpenModalDelete(true);
  };

  const denseHeight = dense ? 52 : 72;

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.realEstate.edit(id));
  };

  const isNotFound = !currentData.length;

  const onCloseDelete = () => {
    setIsOpenModalDelete(false);
  };

  return (
    <Page title="Inquiries">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Inquiries"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Inquiries', href: PATH_DASHBOARD.realEstate.inquiries },
          ]}
        />

        <Box mb="15px">
          <form>
            <Input
              sx={{ marginRight: '15px' }}
              type="search"
              placeholder="Search here"
              onChange={handleChange}
              value={searchInput}
            />
          </form>
        </Box>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
              />

              <TableBody>
                {sortableData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((el) => (
                    <RealEstateInquiriesTableRow
                      el={el}
                      key={el.id}
                      selected={selected.includes(String(el.id))}
                      onSelectRow={() => onSelectRow(String(el.id))}
                      onDeleteRow={() => handleDeleteRow(String(el.id))}
                      onEditRow={() => handleEditRow(String(el.id))}
                    />
                  ))}
                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, newFilteredData?.length)}
                />
                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {currentData?.length && (
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={newFilteredData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        )}
      </Container>

      <DialogAnimate open={isOpenModalDelete} onClose={onCloseDelete}>
        <Box sx={{ pt: 2, pl: 3, pr: 3 }}>Are you confirm delete item</Box>,
        <DialogActions sx={{ px: 3 }}>
          <Button color="error" onClick={onConfirmDelete}>
            Yes
          </Button>
          <Button onClick={onCloseDelete}>Cancel</Button>
        </DialogActions>
      </DialogAnimate>
    </Page>
  );
}
