function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_PAGE = {
  realEstate: '/featured-listings',
  realEstateIdView: (id: string) => `/featured-listings/${id}`,
  about: '/about',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
  },
  realEstate: {
    root: path(ROOTS_DASHBOARD, '/real-estate'),
    list: path(ROOTS_DASHBOARD, '/real-estate/list'),
    new: path(ROOTS_DASHBOARD, '/real-estate/new'),
    inquiries: path(ROOTS_DASHBOARD, '/real-estate/inquiries'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/real-estate/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/real-estate/${id}`),
  },
};
