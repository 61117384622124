import { Container } from '@mui/material';
import Page from 'src/shared/components/Page';
import useSettings from 'src/shared/hooks/useSettings';
import PrivatePolicy from './sections/PrivatePolicy';

export default function PrivacyPolicyPage() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Privacy Policy">
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          marginTop: '100px',
          marginBottom: '100px',
          display: 'grid',
          gap: 3,
        }}
      >
        <PrivatePolicy />
      </Container>
    </Page>
  );
}
