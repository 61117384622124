import { useMemo } from 'react';

export const useSortableData = <T>(items: T[], orderBy: string, order: string) => {
  const keyOrderBy = orderBy as keyof T;

  return useMemo(() => {
    return items.sort((a, b) => {
      if (order === 'asc') {
        return a[keyOrderBy] > b[keyOrderBy] ? 1 : -1;
      }

      return a[keyOrderBy] < b[keyOrderBy] ? 1 : -1;
    });
  }, [items, order, keyOrderBy]);
};
