import { PATH_PAGE } from 'src/routes/paths';
import MainLayout from '../../shared/layouts/main';
import HomePage from './Home/HomePage';
import PrivacyPolicyPage from './PrivacyPolicy/PrivacyPolicyPage';
import RealEstateList from './RealEstate/RealEstateListWeb';
import RealEstatePublic from './RealEstate/RealEstatePublic';
import TermsAndConditionPage from './TermsAndCondition/TermsAndConditionPage';

export default {
  path: '/',
  element: <MainLayout />,
  children: [
    { element: <HomePage />, index: true },
    {
      path: 'featured-listings',
      children: [
        { element: <RealEstateList />, index: true },
        { path: ':id', element: <RealEstatePublic /> },
      ],
    },
    { path: PATH_PAGE.privacyPolicy, element: <PrivacyPolicyPage /> },
    { path: PATH_PAGE.termsAndConditions, element: <TermsAndConditionPage /> },
  ],
};
