import { createContext, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';
import { API, API_ENDPOINTS } from '../shared/utils/api';

import { isValidToken, setSession } from '../shared/utils/jwt';
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../shared/@types/auth';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import RealtorWelcomeDialog from 'src/shared/components/RealtorWelcomDialog';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  ValidateLoginError = 'VALIDATE_LOGIN_ERROR',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.ValidateLoginError]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case 'VALIDATE_LOGIN_ERROR':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    /* add verify action type, and /verify router path
          in /verify router which just renders a spinner, in
          componentDidMount has a setTimeOut for 20secs, call verify method which checks for access token in cookie if presents validates
          */
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response: any = await API.get(API_ENDPOINTS.ME);

        const { user } = response.data;

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);

      dispatch({
        type: Types.ValidateLoginError,
      });

      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = async (email: string, password: string) => {
    const response: any = await API.post(API_ENDPOINTS.LOGIN, {
      email,
      password,
    });

    const { user, accessToken } = response.data;

    setSession(accessToken);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    isRealtor: boolean
  ) => {
    const response: any = await API.post(API_ENDPOINTS.REGISTER_USER, {
      username: firstName,
      user_surname: lastName,
      email,
      password,
      isRealtor,
    });
    const { user, accessToken } = response.data;

    setSession(accessToken);

    if (user.isRealtor) {
      setIsDialogOpen(true);
    }

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        initialize,
      }}
    >
      <>
        <RealtorWelcomeDialog onClose={handleDialogClose} isOpen={isDialogOpen} />
        {children}
      </>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
