import { Box, List, ListItem, Typography } from '@mui/material';

export default function TermsAndConditions() {
  return (
    <Box component="section">
      <Typography variant="h4" sx={{ pb: 2 }}>
        Terms and Conditions
      </Typography>
      <Box component="article" sx={{ display: 'grid', gap: 2 }}>
        <Typography variant="body1">Introduction</Typography>
        <Typography variant="body1">
          These Terms and Conditions ("Terms") govern your use of Delek Homes us website. By using
          our website, you agree to be bound by these Terms. If you do not agree to these Terms, you
          may not use our website.
        </Typography>
        <Typography variant="body1">Use of Our Website</Typography>
        <Typography variant="body1">
          You may use our website for lawful purposes only. You may not use our website for any
          illegal or unauthorized purpose.
        </Typography>
        <Typography variant="body1">Intellectual Property</Typography>
        <Typography variant="body1">
          All content on our website, including text, graphics, logos, images, and software, is the
          property of Delek Homes or its licensors and is protected by intellectual property laws.
        </Typography>
        <Typography variant="body1">Prohibited Conduct</Typography>
        <Typography variant="body1">
          You may not engage in any conduct that:
          <List>
            <ListItem>Violates these Terms</ListItem>
            <ListItem>Interferes with the operation of our website.</ListItem>
            <ListItem>Attempts to gain unauthorized access to our website or its systems.</ListItem>
            <ListItem>Harasses or harms other users of our website.</ListItem>
          </List>
        </Typography>
        <Typography variant="body1">Links to Other Websites</Typography>
        <Typography variant="body1">
          Our website may contain links to third-party websites. We do not endorse or assume any
          responsibility for the content or operation of these websites.
        </Typography>
        <Typography variant="body1">Disclaimer of Warranties</Typography>
        <Typography variant="body1">
          We provide our website "as is" and without any warranty or condition, express, implied, or
          statutory. We do not warrant that our website will be uninterrupted, error-free, or free
          from viruses or other harmful components.
        </Typography>
      </Box>
    </Box>
  );
}
