import axios from './axios';
import { AxiosRequestConfig } from 'axios';

export enum API_ENDPOINTS {
  LOGIN = '/users/login',
  REGISTER_USER = '/users/registration',
  ME = '/users/me',

  ALL_USERS = `users`,
  ALL_ROLES = `users/roles`,
  CREATE_USER = `users`,
  UPDATE_USER = `user/:id`,
  UPDATE_AVATAR = 'user/:id/avatar',
  GET_USER = '/users/:id',
  DELETE_USER = '/users/:id',

  USER_UPDATE_GENERAL = `user/:id`,

  ADD_INQUIRY = 'inquiry',
  GET_INQUIRIES = 'inquiries',
  DELETE_INQUIRIES = '/inquiry/:id',

  UPDATE_USER_ROLES = 'user/roles',

  UPDATE_PASSWORD = 'user/password',

  ALL_REAL_ESTATE = 'estate-objects',
  ALL_REAL_ESTATE_PUBLIC = 'estate-objects/public',
  ALL_REAL_ESTATE_FAVORITES = 'estate-objects/favorites',
  CREATE_REAL_ESTATE = '/estate-objects',
  UPDATE_REAL_ESTATE = '/estate-objects/:id',
  DELETE_REAL_ESTATE = '/estate-objects/:id',
  GET_REAL_ESTATE = '/estate-objects/:id',

  ALL_FAVORITE_REAL_ESTATE = 'estate-favorite',
  ADD_FAVORITE_REAL_ESTATE = '/estate-favorite',
  DELETE_FAVORITE_REAL_ESTATE = '/estate-favorite/:id',
}

export const API = {
  get: <T>(url: string, params?: AxiosRequestConfig<T>) => {
    return axios.get<T>(url, params);
  },
  post: <T>(url: string, data: T, config?: AxiosRequestConfig<T> | undefined) => {
    return axios.post<T>(url, data, config);
  },
  put: <T>(url: string, data: T, params?: AxiosRequestConfig<T> | undefined) => {
    return axios.put<T>(url, data, params);
  },
  patch: <T>(url: string, data: T, params?: AxiosRequestConfig<T> | undefined) => {
    return axios.patch<T>(url, data, params);
  },
  delete: <T>(url: string, params?: AxiosRequestConfig<T>) => {
    return axios.delete(url, params);
  },
};
