import React, { useLayoutEffect, useEffect, useMemo, useState } from 'react';
import { Grid, Container, Box, DialogActions, Button, Pagination } from '@mui/material';

import useSettings from '../../../shared/hooks/useSettings';
import { EstateObjectsResponse } from '../../../shared/@types/blog';
import Page from '../../../shared/components/Page';
import { SkeletonPostItem } from '../../../shared/components/skeleton';
import useFetch from '../../../shared/hooks/useFetch';
import { RealEstateFlter } from './sections/RealEstateFlter';
import RealEstateCardSimple from './sections/RealEstateCardSimple';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import EmptyContent from '../../../shared/components/EmptyContent';
import useAuth from '../../../shared/hooks/useAuth';
import { PATH_AUTH } from '../../../routes/paths';
import { DialogAnimate } from 'src/shared/components/animate';
import { applySort, getRequestUrl } from './helpers';
import { API, API_ENDPOINTS } from '../../../shared/utils/api';
import { FavoriteEvent } from './sections/types';

export default function RealEstateList() {
  const { themeStretch } = useSettings();
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const [isOpenModalAddFavorite, setIsOpenModalAddFavorite] = useState(false);
  const [filters, setFilters] = useState('latest');
  const [page, setPage] = useState(1);
  const [defaultPage, setDefaultPage] = useState(1);
  const [offset] = useState(6);

  const requestUrl = useMemo(() => {
    const price = searchParams.get('price');
    const keyword = searchParams.get('keyword');
    const state = searchParams.get('state');
    const city = searchParams.get('city');
    const bedrooms = searchParams.get('bedrooms');

    let pageParams = page;
    if (keyword || state || city || bedrooms) {
      pageParams = defaultPage;
      setPage(pageParams);
    }

    if (!keyword || !state || !city || !bedrooms) {
      setDefaultPage(1);
      setPage(defaultPage);
    }

    return getRequestUrl({ price, keyword, state, city, bedrooms, page: pageParams, offset });
  }, [searchParams, page]);

  const { data, isLoading } = useFetch<EstateObjectsResponse>(requestUrl);
  const posts = data?.list || [];
  const sortedPosts = applySort(posts, filters);
  const paginationCount = data?.list.length ? Math.round(data!.count / offset) : 0;
  const [favoriteList, setFavoriteList] = useState<Record<number, number>>({});

  const updateFavoriteList = () => {
    API.get(API_ENDPOINTS.ALL_FAVORITE_REAL_ESTATE).then(({ data }: any) => {
      setFavoriteList(
        data!.list.reduce((acc: any, value: any) => {
          acc[value.estateId] = value.id;
          return acc;
        }, {})
      );
    });
  };

  useEffect(updateFavoriteList, []);


  const onToggleItemToFavorite = ({ estateId, favoriteId }: FavoriteEvent) => {
    if (isAuthenticated) {
      if (favoriteId) {
        API.delete(
          API_ENDPOINTS.DELETE_FAVORITE_REAL_ESTATE.replace(':id', favoriteId as unknown as string)
        ).then(updateFavoriteList);
      } else {
        API.post(API_ENDPOINTS.ADD_FAVORITE_REAL_ESTATE, { estateId }).then(updateFavoriteList);
      }
    } else {
      setIsOpenModalAddFavorite(true);
    }
  };

  const onCloseDelete = () => {
    setIsOpenModalAddFavorite(false);
  };

  useLayoutEffect(() => {
    // searchParams
    // setDefaultPage
  }, []);

  console.log('RealEstateList -> favoriteList', favoriteList);

  return (
    <Page title="Estate Objects">
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        style={{ marginTop: 100, marginBottom: 100 }}
      >
        <RealEstateFlter />

        <Grid container spacing={3}>
          {sortedPosts.map((post, index) => {
            return (
              <Grid key={post.id} item xs={12} sm={6} md={4}>
                <RealEstateCardSimple
                  post={post}
                  index={index}
                  favoriteId={favoriteList[post.id as unknown as number]}
                  onToggleItemToFavorite={onToggleItemToFavorite}
                />
              </Grid>
            );
          })}

          {isLoading && [...Array(12)].map((_, index) => <SkeletonPostItem key={index} />)}

          {!isLoading && !sortedPosts.length && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '400px',
              }}
            >
              <EmptyContent
                title="No Data"
                sx={{
                  '& span.MuiBox-root': { height: 160 },
                }}
              />
            </Box>
          )}
        </Grid>

        {sortedPosts.length && paginationCount > 1 && (
          <Pagination
            sx={{ justifyContent: 'center', mt: 5, display: 'flex' }}
            count={paginationCount}
            size="large"
            shape="rounded"
            defaultPage={defaultPage}
            onChange={(ev, pageValue) => {
              setDefaultPage(pageValue)
              setPage(pageValue);
            }}
            color="primary"
            page={page}
          />
        )}

        <DialogAnimate open={isOpenModalAddFavorite} onClose={onCloseDelete}>
          <Box sx={{ pt: 2, pl: 3, pr: 3 }}>If you want add Item to Favorite, please login.</Box>,
          <DialogActions sx={{ py: 3 }}>
            <Button color="success" component={RouterLink} to={PATH_AUTH.login}>
              Login
            </Button>
            <Button color="secondary" onClick={onCloseDelete}>
              Cancel
            </Button>
          </DialogActions>
        </DialogAnimate>
      </Container>
    </Page>
  );
}
